<template>
  <div>
    <div v-show="loadingUserData || !showForm">
      <loading-page title="Validando acesso..." />
    </div>

    <p-form
      ref="form"
      v-if="!loadingUserData"
      v-show="showForm"
      @submit="submitForm"
    >
      <!-- Título e subtítulo -->
      <div class="c-row ui-mb-4">
        <div class="c-col-full">
          <!-- Título -->
          <div class="c-text-h3">
            Dados do aluno(a)
          </div>

          <!-- Subtítulo -->
          <div class="c-text-b2 c-text-gray">
            *Preenchimento obrigatório
          </div>
        </div>
      </div>

      <!-- Nome completo -->
      <div class="c-row ui-mb-4">
        <validation-provider
          class="c-col-full"
          rules="required|full_name"
          v-slot="{ errors }"
          name="Nome completo"
        >
          <p-input
            data-cy="nameField"
            key="nameField"
            label="Nome completo*"
            name="name"
            v-model.trim="form.name"
            :error-message="errors[0]"
            :mask="fullNameRegex"
          />
        </validation-provider>
      </div>

      <!-- CPF e Telefone -->
      <div class="c-row ui-mb-4">
        <!-- CPF -->
        <validation-provider
          class="c-col-full sm:c-col"
          rules="required|cpf"
          v-slot="{ errors }"
          name="CPF"
          vid="cpf"
        >
          <p-input
            data-cy="cpfField"
            key="cpfField"
            label="CPF*"
            name="cpf"
            v-model="form.cpf"
            :error-message="errors[0]"
            mask="000.000.000-00"
            hint="Somente números"
            icon-info="info"
            :disabled="!allowEditCpf"
          />
        </validation-provider>

        <!-- Telefone -->
        <validation-provider
          class="c-col-full sm:c-col"
          rules="required|length:11"
          :custom-messages="{ length: 'Celular inválido' }"
          v-slot="{ errors }"
          name="Celular"
        >
          <p-input
            data-cy="phoneField"
            key="phoneField"
            label="Celular*"
            name="phone"
            v-model="form.phone"
            :error-message="errors[0]"
            mask="(00) 0 0000-0000"
            hint="DDD + nº do celular"
            icon-info="info"
          />
        </validation-provider>

        <!-- Data de nascimento -->
        <validation-provider
          class="c-col-full sm:c-col"
          :rules="{ required: true, after: dateMinusYear(120), before: dateMinusYear(0) }"
          name="Data de nascimento"
          v-slot="{ errors }"
        >
          <p-input
            type="date"
            data-cy="birthdayField"
            key="birthdayField"
            label="Data de nascimento*"
            name="birthday"
            v-model="form.birthday"
            hint="Data de nascimento"
            icon-info="info"
            :error-message="errors[0]"
          />
        </validation-provider>
      </div>

      <separator />

      <!-- Endereço -->
      <p-address-form
        :address="form.address"
        :submitting="isFormSending"
        @cepAddress.once="handleFollowRedirect"
      />

      <separator />

      <!-- Botão Continuar e Texto Sair -->
      <div class="c-row ui--mb-4">
        <!-- Botão Continuar -->
        <div class="c-col-full sm:c-col ui-mb-4">
          <c-button
            data-cy="registerSubmitButton"
            type="submit"
            size="lg"
            :loading="isFormSending"
            class="ui-w-full"
          >
            Continuar
            <icon
              name="next"
              class="c-icon--right"
            />
          </c-button>
        </div>

        <!-- Texto Sair -->
        <div class="c-col-full sm:c-col ui-mb-4 c-d-flex ui-items-center">
          <div class="c-text-gray">
            <span class="c-text-b2">Não é você? </span>
            <router-link
              :to="{ name: 'logout', query: { target: logoutEncodedTarget } }"
              class="c-text-hyperlink"
            >
              Sair
            </router-link>
          </div>
        </div>
      </div>
    </p-form>

    <form
      v-if="concursos"
      method="POST"
      :action="concursos.url"
      class="c-d-none"
      ref="formConcursos"
    >
      <input
        type="hidden"
        name="access_token"
        :value="concursos.token"
      >
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CButton, Icon } from '@estrategiahq/coruja-web-ui';
import { cpfAlreadyExistsError } from '@/utils/errors';
import { User } from '@/models/user';
import PForm from '@/components/p-form';
import PInput from '@/components/p-input';
import Separator from '@/components/separator';
import PAddressForm from '@/components/p-address-form';
import LoadingPage from '@/components/loading-page';
import { DADOS_COMPLETOS_LOCALSTORAGE_KEY } from '@/consts';
import { UserStoreKeys } from '@/store/modules/user';
import { getToken } from '@/services/token';
import { getTargetFromUrl, isTargetFromConcursos, isTargetFromSomeAllowedHosts } from '@/utils/url';
import { ValidationProvider } from 'vee-validate';
import { dateMinusYear } from '@/utils/date';
import { FULL_NAME_REGEX } from '@/utils/regex';

export default {
  components: {
    Icon,
    CButton,
    PForm,
    PInput,
    Separator,
    PAddressForm,
    LoadingPage,
    ValidationProvider,
  },
  data: function() {
    return {
      form: new User(),
      showForm: false,
      logoutEncodedTarget: '',
      isFormSending: false,
      concursos: null,
      allowEditCpf: true,
      fullNameRegex: FULL_NAME_REGEX,
    };
  },
  async mounted() {
    await this.$store.dispatch(UserStoreKeys.ACTION_FETCH_LOGGED_USER);
    this.form = { ...this.loggedUser, address: { ...this.loggedUser.address } };

    if (!this.form.address || !this.form.address.zipCode) {
      this.showForm = true;
    }

    this.allowEditCpf = !this.form.cpf;
  },
  computed: {
    ...mapGetters({
      loggedUser: UserStoreKeys.GET_LOGGED_USER,
      loadingUserData: UserStoreKeys.GET_REQUEST_LOADING,
    }),
  },
  methods: {
    dateMinusYear(year) {
      return dateMinusYear(year);
    },
    async handleFollowRedirect() {
      if (await this.$refs.form.validate({ silent: true })) {
        return this.followRedirect();
      }

      this.showForm = true;

      const encodedQuery = new URLSearchParams(this.$route.query);
      this.logoutEncodedTarget = window.location.origin + '/login?' + encodedQuery.toString();
    },
    async submitForm() {
      this.isFormSending = true;
      try {
        await this.$services.accounts.updateComplementaryData(this.form);
        this.$services.events.notifyComplementaryDataFilled();
        return this.followRedirect();
      } catch (e) {
        let toastMessage = 'Não conseguimos enviar a informação. Tente novamente em alguns minutos.';

        if (e === cpfAlreadyExistsError) {
          const email = e.meta.email;
          this.$refs.form.setErrors({
            cpf: [`CPF já cadastrado para o email ${email}`],
          });
          toastMessage = 'Este CPF já está associado a uma conta existente.';
        }

        this.$toast.show({
          type: 'error',
          text: toastMessage,
        });
      } finally {
        this.isFormSending = false;
      }
    },
    followRedirect() {
      const target = getTargetFromUrl();
      localStorage.setItem(DADOS_COMPLETOS_LOCALSTORAGE_KEY, '1');

      if (isTargetFromConcursos(target)) {
        this.concursos = {
          url: target,
          token: getToken(),
        };
        this.$nextTick(() => this.$refs.formConcursos.submit());
        return;
      }

      const allowedTargetHosts = this.$env.VUE_APP_ALLOWED_TARGET_HOSTS.split(',');
      if (isTargetFromSomeAllowedHosts(target, allowedTargetHosts)) {
        location.href = target;
        return;
      }

      return this.$router.push('/');
    },
  },
};

</script>
